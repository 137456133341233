.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: white;
    color: black;
    height: 30px;
}

.navbar-logo img {
    max-width: 200px;
    height: 50px;
}

.navbar-buttons button {
    background-color: #ff4500;
    color: #fff;
    border: none;
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}

a {
    text-decoration: none;
    color: white;
}

@media screen and (max-width:756px) {
    .navbar-logo img {
        height: 50px;
    }
    .navbar {
        padding-left: 0px;
        padding-right: 0px;
    }
}