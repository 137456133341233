.card-container {
    height: 100vh;
    background-color: rgb(114, 88, 114);
    margin: 30px;
    border-radius: 20px;
}

.card-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    width: 250px;
    height: 280px;
    margin: 10px;
    border: 1px solid white;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
}

.card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
}

.card-title {
    margin: 10px 0;
    font-size: 18px;
    text-align: center;
    font-family: "Georgia";
    color: black;
}

.order-button {
    width: 100%;
    padding: 10px;
    background-color: brown;
    color: #fff;
    border: none;
    border-radius: 0;
    cursor: pointer;
    transition: background-color 0.3s;
}

.order-button:hover {
    background-color: black;
}

.tag-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.tag {
    color: #fff;
}

.card-footer {
    margin-bottom: 30px;
    text-align: center;
    color: rgb(215, 216, 133);
    font-family: "Georgia";
    font-size: 40px;
}

@media screen and (max-width:756px) {
    .card-row {
        flex-direction: column;
    }
    .card-container {
        margin: 0px;
        height: auto;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding-bottom: 30px;
    }
}