.footer-container {
    text-align: center;
    background-color: black;
    border-top-left-radius: 10%;
    border-top-right-radius: 10%;
    padding: 30px;
    margin-top: 30px;
}

.footer {
    line-height: 1;
}

.footer-content {
    line-height: 1;
}